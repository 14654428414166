<template>
  <el-dialog
    customClass="InoutDialogCURD"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>

    <!-- 弹窗内容 -->
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">

      <el-row :gutter="24">
        <el-col :span="12">
          <!-- 表单认证(点位类型) -->
          <el-form-item label="点位类型" prop="spaceTypeName">
            <el-input v-model="form.spaceTypeName"  prefix-icon="el-icon-edit" placeholder="请输入设备名称" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 表单认证(点位名称) -->
          <el-form-item label="点位名称" prop="spaceName">
            <el-input v-model="form.spaceName"  prefix-icon="el-icon-edit" placeholder="请输入点位名称" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="12">
          <!-- 表单认证(关联-小区/楼栋/房屋) -->
          <el-form-item :label="`关联-小区/楼栋/房屋 (${spacesRelationLable})`" prop="spacesRelation">
            <el-cascader v-show="!cascader.isSpaceTypeEqTree"  ref="cascader" placeholder="请关联-小区/楼栋/房屋" v-model="form.spacesRelation" :options="cascader.spaces" :props="cascader" @change="handleCascaderChange" @visible-change="handleCascaderVisibleChange" :disabled="isDisable" class="w-full" clearable></el-cascader>
            <el-input v-show="cascader.isSpaceTypeEqTree"  v-model="form.villageName"  prefix-icon="el-icon-edit" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 表单认证(关联-设备) -->
          <el-form-item label="关联-设备" prop="deviceCodes">
            <el-select multiple v-model="form.deviceCodes" placeholder="请关联-设备" class="w-full" :disabled="isDisable">
              <el-option-group v-for="group in deviceListOptions" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.value" :label="item.device_name" :value="item.device_code" :disabled="item.disabled"></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button  size="small" type="relief" color="grey"     @click="handleClose" class="mr-5">取 消</vs-button>
      <vs-button  size="small" type="relief" color="danger"   @click="delInOut" class="mr-5 vs-con-loading__container" v-if="isDisable && this.operate == 'DELETE'">删除</vs-button>
      <vs-button  size="small" type="relief" color="primary"  @click="submitForm('ruleForm')" class="vs-con-loading__container"      v-if="!isDisable">确 定</vs-button>
    </span>
  </el-dialog>
</template>

<script>
import TreeSelectGroup from "@/components/el-tree-select/ElTreeSelectGroup.vue";
import ElTreeSelectGroup from '../../../../components/el-tree-select/ElTreeSelectGroup.vue';

export default {
  props: ["dialogFormVisible", "dialogFormData", "dialogFormOpt", "deviceType"],
  data() {
    return {
      reFresh: false,
      dialogTitle: "",
      operate: this.dialogFormOpt,
      isVisible: this.dialogFormVisible,
      isDisable: false,
      spacesRelationLable: "",
      form: {
        villageCode: "",
        villageName: "",
        spaceCode: "",
        spaceName: "",
        spaceType: 0,
        spaceTypeName: "",
        deviceCodes: [],
        spacesRelation: []
      },
      cascader: {
        recover: false,
        emitPath: false,
        multiple: false,
        checkStrictly: true,
        isSpaceTypeEqTree: false,
        expandTrigger: 'hover',
        spaces: []
      },
      deviceListOptions: [],
      checkDeviceList: [],
      rules: {
        spaceTypeName: [
          { required: true, message: '请输入点位类型', trigger: 'blur' }
        ],
        spaceName: [
          { required: true, message: '请选择点位名称', trigger: 'change' }
        ],
        deviceCodes: [
          { required: false, message: '请选择设备厂商+型号', trigger: 'blur' }
        ],
        spacesRelation: [
          { required: true, message: '请选择相关空间', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {},
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      console.log("dialogFormData", val);
      this.reFresh = false;

      // 设备所需参数
      this.form = this.decodeParam(val);

      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt;
      switch (val) {
        case "CREATE":
          this.dialogTitle = "创建点位";
          this.isDisable = false;
          break;
        case "READ":
          this.dialogTitle = "查看点位";
          this.isDisable = true;
          break;
        case "UPDATE":
          this.dialogTitle = "更新点位";
          this.isDisable = false;
          break;
        case "DELETE":
          this.dialogTitle = "删除点位";
          this.isDisable = true;
          break;
      }
    }
  },
  methods: {
    // 添加设备
    async addInOut() {
      try {
        let params = this.encodeParam(this.form);
        let result = await this.$apis.ODC("添加出入口", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 修改设备
    async updateInOut() {
      try {
        let params = this.encodeParam(this.form);
        let result = await this.$apis.ODC("更新出入口", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 删除设备
    async delInOut() {
      try {
        let params = {
          spaceCode: this.form.spaceCode,
          villageCode: this.form.villageCode
        };
        let result = await this.$apis.ODC("删除出入口", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 修改提交参数
    encodeParam(form) {
    
      // spacesRelation 单选会变成字符串 需要转换成数组
      form.spacesRelation = typeof(form.spacesRelation) ==  "string" ? [form.spacesRelation] : form.spacesRelation
      // deviceCodes 单选会变成字符串 需要转换成数组
      form.deviceCodes = typeof(form.deviceCodes) ==  "string" ? [form.deviceCodes] : form.deviceCodes

      return form;
    },
    // 恢复提交参数
    decodeParam(val) {
      let form = JSON.parse(JSON.stringify(this.form));
      
      form.villageCode = val.villageCode;
      form.villageName = val.villageName;
      form.spaceType = Number(val.spaceType);
      form.spaceTypeName = val.spaceTypeName;

      form.spaceCode = val.space_code || "";
      form.spaceName = val.space_name || "";
      form.deviceCodes = val.deviceCodes || [];

      // 设备源数据
      let deviceList = val.deviceList || {};

      // 设备多选框数据
      let deviceTree = [];
      Object.keys(deviceList).map(item => { 
        let deviceItem = deviceList[item];
        deviceItem.map((val, key) => {
          if(val.canChoose == 0) {
            deviceItem[key]["disabled"] = true;
          } else {
            deviceItem[key]["disabled"] = false;
          }
        })
        deviceTree.push( { "label": item, options: deviceList[item]})
      });
      this.deviceListOptions = deviceTree;

      // 获取已勾选的设备信息
      let checkedDeviceArr = []
      let spaceDetail = val.spaceDetail;
      if (spaceDetail) {
        spaceDetail.deviceList.forEach(element => {
          checkedDeviceArr.push(element.device_code);
        });
      };
      form.deviceCodes = checkedDeviceArr;

      // 楼栋信息
      this.cascader.spaces = this.showDataForSpaceType(val.spaceType, val.spaceTree);

      // 获取已勾选的楼栋信息 （只有 7 多选）
      if (spaceDetail) {
        if (  val.spaceType == 7  ) {
          form.spacesRelation = spaceDetail.inoutDetail.spaces_relation;
        } else {
          form.spacesRelation = spaceDetail.inoutDetail.spaces_relation[0];
        }
      }

      // spaceType = [4,5,6] 社区值 取 当前社区
      if ( val.spaceType == 4 || val.spaceType == 5 || val.spaceType == 6) {
         form.spacesRelation = [this.form.villageCode];
      }

      return form;
    },
    // 单元/楼栋/房屋 变化时
    handleCascaderChange(value) {

      let checkedArr = value;
      let cascaderSpaces = JSON.parse(JSON.stringify(this.cascader.spaces));
    
      // 同级选择 - 上/下全部关闭
      this.cascader.spaces = this.recoverSpaces(cascaderSpaces, checkedArr);

    },
    // 单元/楼栋/房屋 打开时
    handleCascaderVisibleChange(state) {
      if (state == true) {
        this.handleCascaderChange(this.form.spacesRelation);
      }
    },
    // 按空间类型判断展示类型
    showDataForSpaceType(spaceType, Spaces) {

      var cascaderSpaces = JSON.parse(JSON.stringify(Spaces));

      if (spaceType == 1 || spaceType == 2 || spaceType == 8 ) {   // 可以是任意的位置, 可以是 小区 ｜ 楼栋 ｜ 单元  (单选)

        this.cascader.multiple = false;
        this.cascader.recover = false;

        this.spacesRelationLable = "单选"

        this.recursionCheck(cascaderSpaces, [], 2, 0, false);

      } else if ( spaceType == 3  ) {             // 只能是单元 (单选)

        this.cascader.multiple = false;
        this.cascader.recover = false;

        this.spacesRelationLable = "单选"

        this.recursionCheck(cascaderSpaces, [], 1, 3, true);

      } else if ( spaceType == 4 || spaceType == 5 || spaceType == 6) {   // 只能是小区 (单选)

        this.cascader.multiple = false;
        this.cascader.recover = false;
        this.cascader.isSpaceTypeEqTree = true;

        this.spacesRelationLable = "单选"

        this.recursionCheck(cascaderSpaces, [], 1, 4, true);

      } else if ( spaceType == 7  ) {             // 多空间出入口,  可以是小区 | 楼栋 ｜ 单元  (多选)

        this.cascader.multiple = true;
        this.cascader.recover = true;

        this.spacesRelationLable = "同级多选"

      } else {

        this.cascader.multiple = false;
        this.cascader.recover = false;

        this.spacesRelationLable = "单选"

      }

      return cascaderSpaces;

    },
    // 递归 树上下级关闭
    recoverSpaces(arr, checkedArr) {
      let level = this.$refs["cascader"].getCheckedNodes()[0] ? this.$refs["cascader"].getCheckedNodes()[0].level : 0;

      if (this.cascader.recover) this.recursionCheck(arr, checkedArr, 1, level, true);

      return arr;
    },
    recursionCheck(array, checkedArray, index, level, isGo) {
      for (let item of array) {
        // 改变数据格式
        if (level != index && level != 0) {
          item.disabled = true;
        } else if (level == 0) {
          item.disabled = false;
        }
        // 判断下子集
        if (item.children && isGo) {
          this.recursionCheck(item.children, checkedArray, index+1, level);
        }
      }
    },
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          // 开启loading
          this.$cFun.openDomLoad(this.$vs.loading, el);
           // 判断下操作
          if (this.operate === "CREATE") {
            await this.addInOut();
          } else if (this.operate === "UPDATE") {
            await this.updateInOut();
          }
        } else {
          return false;
        }
      });
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 删除事件
    async delForm() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, "danger");
      // 提交API删除角色
      await this.delEquipment();
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false;
      if (this.isVisible == false) {
        this.resetForm('ruleForm');
        this.$emit("handleDiaLogClose", isRefresh);
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById("content-area");
      if (dom) {
        if (val) {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .concat(" ")
              .concat("dialog-show-overlay")
          );
        } else {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .replace(" ", "")
              .replace("dialog-show-overlay", "")
          );
        }
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.emptyData();
    },
    // 清空数据
    emptyData() {
      this.form = {
        villageCode: "",
        villageName: "",
        spaceCode: "",
        spaceName: "",
        spaceType: 0,
        spaceTypeName: "",
        deviceCodes: [],
        spacesRelation: []
      }
    }
  },
  created() {},
  destroyed() {
    this.changeNavBarShadow();
  },
  components: {
    TreeSelectGroup
  }
};
</script>

<style lang="scss" scope="scope">
  .InoutDialogCURD {
    width: 50%;
    min-width: 350px;
  }
</style>
